<template>
  <div class="realtor">
    <!-- 查询表单 -->
    <a-form-model ref="searchForm" layout="horizontal" :label-col="{ xs: 8 }" :wrapper-col="{ xs: 16 }">
      <a-row :gutter="8">
        <a-col :md="8" :xl="6" :xxl="6" :sm="12">
          <a-form-model-item label="经销商名称">
            <a-input v-model="searchData.name" placeholder="请输入经销商名称"></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :md="8" :xl="6" :xxl="6" :sm="12">
          <a-form-model-item label="大区名称">
            <a-input v-model="searchData.area" placeholder="请输入大区名称"></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :md="8" :xl="6" :xxl="6" :sm="12">
          <a-form-model-item label="事务所名称">
            <a-input v-model="searchData.firmName" placeholder="请输入事务所名称"></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="经销商类型">
              <DictSelect field="dealerType" :value.sync="searchData.dealerType" style="width: 100%" placeholder="订单状态"></DictSelect>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="合作状态">
              <DictSelect field="cooperateStatus" :value.sync="searchData.cooperateStatus" style="width: 100%" placeholder="生成方式"></DictSelect>
            </a-form-model-item>
          </a-col>
        <a-col :md="8" :xl="6" :xxl="6" :sm="12">
          <a-form-model-item label="联系人">
            <a-input v-model="searchData.contacts" placeholder="请输入联系人"></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :md="8" :xl="6" :xxl="6" :sm="12">
          <a-form-model-item label="联系电话">
            <a-input v-model="searchData.phone" placeholder="请输入联系人电话"></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :md="8" :xl="6" :xxl="6" :sm="12">
          <a-form-model-item label="帐号">
            <a-input v-model="searchData.account" placeholder="请输入帐号"></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :xxl="6" :xl="5" :md="8" sm="2">
          <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
            <a-button type="primary" @click="getDataFilter()" icon="search">查询</a-button>
            <a-button type="default" @click="reset()" icon="sync">重置</a-button>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <div>
      <a-button type="primary" @click="toHandler('add')" icon="plus" v-if="isCusInfoAdd">新增</a-button>
      <a-button type="primary" v-if="selectedRowKeys.length <= 1 && isCusInfoEdit"  @click="toHandler('edit')" icon="edit">编辑</a-button>
      <a-button type="primary" v-if="selectedRowKeys.length <= 1 && isCusInfoDel" @click="toHandler('del')" icon="delete">删除</a-button>
      <a-button type="primary" v-if="selectedRowKeys.length <= 1 && isCusInfoSelect" @click="toHandler('check')" icon="copy">查看</a-button>
      <a-button type="default" v-if="selectedRowKeys.length <= 1 && isCusInfoPwd" @click="onPassword">重置密码</a-button>

    </div>
    <!-- 数据表格 -->
    <a-table
      :loading="tableLoading"
      :row-selection="{type: 'radio', selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      :rowKey="(record) => record.id"
      :columns="columns"
      :data-source="tableData"
      bordered
      :pagination="page"
      @change="changeTable">
      <span slot="dealerType" slot-scope="text">
        <a-tag v-if="text == 1">中心店</a-tag>
        <a-tag v-if="text == 2">核心店</a-tag>
        <a-tag v-if="text == 3">一般店</a-tag>
        <a-tag v-if="text == 4">核心店（预备）</a-tag>
      </span>
      <span slot="cooperateStatus" slot-scope="text">
        <a-tag v-if="text == 1" color="#87d068">合作中</a-tag>
        <a-tag v-if="text == 2">合作终止</a-tag>
      </span>
    </a-table>
    <!-- 编辑表单 -->
    <CustomerInfo-edit-modal ref="CustomerInfoEditModal" @reload="getData"></CustomerInfo-edit-modal>
      <!--查看-->
    <Password ref="password"></Password>
  </div>
</template>

<script>
import Password from './components/password'
import { columns } from './components/colums.js'
import CustomerInfoEditModal from './components/CustomerInfoEditModal.vue'
import { delCustomerInfo, listCustomerInfo, selectByIdCustomerInfo } from './api/CustomerInfoApi'
import { checkPermission } from '@/utils/permissions'

const pageSource = {
  current: 1,
  pageSize: 10,
  total: 0,
}
export default {
  name: 'customerInfo',
  components: {
    CustomerInfoEditModal,
    Password,
  },
  data() {
    return {
      columns: columns,
      tableData: [],
      searchData: {}, // 搜索条件
      scroll: { y: 600 },
      tableLoading: false, //表格loading
      page: {
                current: 1,
                pageSize: 10,
                total: 0,
                showSizeChanger: true,
                showTotal: () => `共${this.page.total}条`,
            },
      selectedRowKeys: [],
      selectedRows: [],
      statusList: [],
      item: [],
      isCusInfoAdd: checkPermission("cus:info:add"),
      isCusInfoEdit: checkPermission("cus:info:edit"),
      isCusInfoDel: checkPermission("cus:info:del"),
      isCusInfoSelect: checkPermission("cus:info:select"),
      isCusInfoPwd: checkPermission("cus:info:select"),
      isCusInfoFreeze: checkPermission("cus:info:freeze"),
      isCusInfoThaw: checkPermission("cus:info:thaw"),
      status: [
        {
          key: ' ',
          label: '全部',
        },
        {
          key: true,
          label: '正常',
        },
        {
          key: false,
          label: '冻结',
        },
      ],
    }
  },
  computed: {
    rowSelection() {
      const _this = this
      return {
        fixed: true,
        type: 'radio', //"radio"
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys
          _this.selectedRows = selectedRows
        },
      }
    },
    // custoStatus() {
    //   if(this.statusList.length > 0) {
    //     return this.statusList.array.every(value => {
    //       return value != this.statusList[0]
    //     })
    //   }else {
    //     return true
    //   }
    // }
  },
  methods: {
    /**
     * 获取表格数据
     */
    getData() {
      this.selectedRows = []
      this.selectedRowKeys = []
      this.tableLoading = true
      listCustomerInfo({
        pageNumber: this.page.current,
        pageSize: this.page.pageSize,
        ...this.searchData,
      })
        .then((res) => {
          if (res.code === 200) {
            const { total, records } = res.body
            this.page.total = total
            this.tableData = records
          }
        })
        .finally(() => (this.tableLoading = false))
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.item = selectedRows
    },
    /**
     * 筛选查询
     */
    getDataFilter() {
      this.page = Object.assign({}, this.page)
      this.getData()
    },
    /**
     * 重置
     */
    reset() {
      this.searchData = {}
      this.page = Object.assign({}, this.page)
      this.getData()
    },
    /**
     * 更改分页
     */
    changeTable(pagination) {
      this.page = pagination
      this.getData()
    },
    handleChange(value) {},
    /**
     * 点击行选中
     */
    changeTableRow(record) {
      return {
        on: {
          click: (e) => {
            console.log(e)
            if (this.selectedRowKeys[0] === record.id) {
              this.selectedRowKeys = []
              this.selectedRows = []
            } else {
              this.selectedRowKeys = [record.id]
              this.selectedRows = [record]
            }
          },
        },
      }
    },
    onPassword() {
      const _this = this
      if (_this.item.length === 0) {
        _this.$notification.warning({
          message: '请选择一条记录',
        })
        return
      }
      _this.$refs.password.onsetRowData(_this.item[0])
    },
    toHandler(name) {
      const _this = this
      if (name === 'add') {
        _this.$refs.CustomerInfoEditModal.setRowData({}, 'add')
        return
      }
      if (_this.item.length === 0) {
        _this.$notification.warning({
          message: '请选择一条记录',
        })
        return
      }
      switch (name) {
        case 'edit':
          _this.$refs.CustomerInfoEditModal.setRowData(_this.item[0], 'edit')
          break
        case 'del':
          _this.$confirm({
            title: '删除警告',
            content: '确认要删除此条记录吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await delCustomerInfo(_this.item[0].id)
              if (res.code === 200) {
                _this.$notification.success({ message: res.message })
                _this.getData()
              } else {
                _this.$notification.error({ message: res.message })
              }
            },
            onCancel() {},
          })
          break
        case 'check':
          _this.$refs.CustomerInfoEditModal.setRowData(_this.item[0], 'check')
          break
      }
    },
  },
  created() {
    this.getData()
  },
}
</script>

<style lang="less" scoped>
.ant-btn {
  margin-right: 8px;
  margin-bottom: 12px;
}
</style>
